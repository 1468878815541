import { IonButton,  IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import firebase, { createUserToDB } from './../lib/firebase'

export interface NewUserModalProps {
    showNewUserModal: boolean,
    handleShowNewUserModal: any,
    id: any

}

const NewUserModal: React.FC<NewUserModalProps> = ({ showNewUserModal, handleShowNewUserModal, id }) => {

    const [userCredential, setUserCredential] = useState({
        id: "",
        displayName: "",
        email: "",
        guestUser: true,
        phoneNumber: ''
    });

    const { displayName, email, phoneNumber } = userCredential



    useEffect(() => {
        setUserCredential({
            ...userCredential,
            id: firebase.auth().currentUser?.uid || '',
            displayName: firebase.auth().currentUser?.displayName || '',
            email: firebase.auth().currentUser?.email || '',
        })
    }, [firebase.auth().currentUser])

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setUserCredential({ ...userCredential, [name]: value });
    };

    return (

        <IonModal
            isOpen={showNewUserModal}
            onDidDismiss={() => handleShowNewUserModal(false)}
        >
            <IonContent>

                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel className="new-user-modal" position="floating">
                                    Display Name
            </IonLabel>
                                <IonInput
                                    onIonChange={handleChange}
                                    name="displayName"
                                    value={displayName}
                                ></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>

                        <IonCol size="12">
                            <IonItem>
                                <IonLabel className="new-user-modal" position="stacked">
                                    E-mail
            </IonLabel>
                                {/*                                 <span className="message">
                                    This will be shown to team members and guest
            </span> */}
                                <IonInput
                                    onIonChange={handleChange}
                                    name="email"
                                    value={email}
                                ></IonInput>
                            </IonItem>
                        </IonCol>

                    </IonRow>


                    <IonRow>

                        <IonCol size="12">
                            <IonItem>
                                <IonLabel className="new-user-modal" position="stacked">
                                    Phone Number
            </IonLabel>

                                <IonInput
                                    onIonChange={handleChange}
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    placeholder='Enter Your Phone Number'
                                    type='number'
                                ></IonInput>
                            </IonItem>
                        </IonCol>

                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton onClick={() => {
                                createUserToDB(id, { ...userCredential, searchAbleId: id }, true);
                                handleShowNewUserModal()
                            }}>
                                Submit
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

        </IonModal>

    );
}

export default NewUserModal;