import React, { useContext, useEffect, useState } from 'react'
import './store-profile-header.css';
import { IonAlert, IonButton, IonIcon } from '@ionic/react'
import { walletOutline } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';
import { userContext } from '../../context/user-context';
import { getMyStoreData } from '../../lib/firebase';
import { StoreContext } from '../../context/store-context';
import { deleteStore } from '../../lib/store';
export interface StoreProfileHeaderProps {
    handleClick?: any,
    name: any,
    imageUrl: any,
    discountTotal?: any,
    status?: any,
    handleStatus?: any
}


const StoreProfileHeader: React.FC<StoreProfileHeaderProps> = ({ handleClick, name, imageUrl, discountTotal, status, handleStatus }) => {
    const [isAlertOpen,setIsAlertOpen] = useState(false)
    const { storeDiscount,storeData } = useContext(StoreContext)
    const { currentUser } = useContext(userContext)

    const history = useHistory()
    return (
        <div className='profile-header-box'>
            <div className="profile-header">
                <h3 className='d-flex align-items-center'>
                    <span className='avator mr-2'>M</span> <a onClick={() => history.push(`/update-store/${currentUser.id}`) } className="edit-link">Edit Store</a>
                    {name}
                    </h3>
                <IonButton className='btn-red-cus' onClick={handleClick}>
                    <IonIcon slot='start' icon={walletOutline} />
                    Create A Discount
                </IonButton>

            </div>

            <div className="d-flex align-items-center justify-content-between mx-3 py-2">
                <span className=''>Available Discount: {storeDiscount.length}</span>
                <div>
                    <IonButton onClick={() => handleStatus('active')} fill="clear" className={`${status === 'active' && 'btn-status-active'} profile-filter-button profile-filter-button--left ion-no-margin`}>Active</IonButton>
                    <IonButton onClick={() => handleStatus('pending')} fill="clear" className={` ${status === 'pending' && 'btn-status-active'} profile-filter-button profile-filter-button--right ion-no-margin`}>Pending</IonButton>
                </div>
            <IonButton className='ml-2 btn-red-cus' onClick={() => setIsAlertOpen(!isAlertOpen)}>Delete Store</IonButton>

            </div>
        <IonAlert
          isOpen={isAlertOpen}
          onDidDismiss={() => setIsAlertOpen(false)}
          cssClass='my-custom-class'
          header={'Delete Your Store?'}
          message={'Do you sure to delete the Store?'}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel: blah');
              }
            },
            {
              text: 'Okay',
              handler: async () => {
                deleteStore(storeData,currentUser)
                
              }
            }
          ]}        />

        </div>
    );
}

export default StoreProfileHeader;