import { createContext } from "react";


export const userContext: any = createContext({
    isUserExist: undefined,
    currentUser: {},
    setCurrentUser: () => { },
    accessCredential: '',
    setAccessCredential: () => { }

});
