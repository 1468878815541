import React from "react";
import './custom-input.css';
import { IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import { people } from "ionicons/icons";

export interface CustomInputProps {
    label?: string,
    handleChange?: any,
    handleActive?: any,
    active?: any,
    name?: string,
    value: string | number | null,
    placeholder?: string,
    type?: any,
    children?: any
}

const CustomInput: React.FC<CustomInputProps> = ({ label, handleChange, handleActive, active, children, name, ...otherProps }) => {
    return (
        <IonItem lines='none'>
            {label && (
                <IonLabel
                    position="stacked"
                    className="store-input-label"
                >
                    {label}
                </IonLabel>
            )}

            <IonInput
                {...otherProps}
                name={name}
                onIonChange={(e) => handleChange && handleChange(e)}
                onIonFocus={handleActive && handleActive(name)}
                className={`store-input ${active === name && 'store-input--active'}`}>
                {children}

            </IonInput>
        </IonItem>
    );
}

export default CustomInput;