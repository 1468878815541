import './search-input.css'
import React from 'react';
import { IonChip, IonIcon, IonInput } from '@ionic/react';
import { closeCircleOutline, search } from 'ionicons/icons';
export interface SearchInputProps {
    value: any,
    handleChange: any,
    handleClear: any,
    handleFocus?: any,
    focus?: boolean
}

const SearchInput: React.FC<SearchInputProps> = ({ value, handleChange, handleClear, handleFocus, focus }) => {
    return (
        <IonChip className={`input-groups-custom ${focus && 'border-round-focus'}`} >
            <IonInput className='search-Input-field'
                value={value}
                onIonChange={(e) => handleChange(e)}
                onIonFocus={() => handleFocus(true)}
                placeholder='Search By name or brand'

            />

            <div className="input-icons d-flex">
                <div className="input-icon">
                    <IonIcon color="#ADB5BD" icon={search} />
                </div>
                <div className="input-icon-1 " onClick={() => handleClear()}>
                    <IonIcon color="#ADB5BD" icon={closeCircleOutline} />
                </div>

            </div>
        </IonChip>
    );
}

export default SearchInput;