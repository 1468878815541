import { IonButton, IonContent, IonGrid, IonItem, IonLabel, IonModal, IonSelect, IonSelectOption } from "@ionic/react";
import { useState } from "react";
import CustomInput from "./custom-input";

import './accountTypeModal.css'
export interface AccountTypeModalProps {
    isOpen: boolean,
    handleSubmit: any
    
}
 
const AccountTypeModal: React.FC<AccountTypeModalProps> = ({isOpen, handleSubmit}) => {
    const [ accountCredential,setAccountCredential ] = useState<any>({
        mobileNumber: null,
        selected: 'homeowner',
        isHomeOwner: true
    })

    const { mobileNumber, selected,isHomeOwner } = accountCredential;
    return ( 

        <IonModal isOpen={isOpen}>

            <IonContent>
                <IonGrid>
                    <CustomInput
                    handleChange={(e:any) => {
                        console.log(e)

                        setAccountCredential({
                            ...accountCredential,
                            mobileNumber: e.detail.value
                        })

                    }}
                    label='Input your mobile number'
                    type='number'
                    placeholder='Mobile Number'
                    value={mobileNumber}
                    />
                </IonGrid>
                <IonGrid>

                    <div >
                    <IonLabel                     position="stacked"
                    className="store-input-label store-input-label--modified">Select Account Type</IonLabel>
                    <IonItem lines='none' className='select-catagory'>
                        
                                       <IonSelect value={selected} okText="Okay" cancelText="Dismiss" onIonChange={e =>{ 
                                           console.log(e.detail.value)
                                        setAccountCredential({
                                            ...accountCredential, 
                                            selected:e.detail.value,
                                             isHomeOwner: e.detail.value === 'businessOwner' ? false : true})
                                        }}>

              <IonSelectOption value="homeowner">Home Owner</IonSelectOption>
              <IonSelectOption value="businessOwner">Business Owner</IonSelectOption>

            </IonSelect> 
                    </IonItem>                        
                    </div>



                    <IonButton className='ml-3' onClick={() => handleSubmit(accountCredential)}>Submit</IonButton>

                </IonGrid>
            </IonContent>

        </IonModal>
     );
}
 
export default AccountTypeModal;