import React from "react";
import './sucess-modal.css';
import {
    IonText,
    IonButton,
    IonIcon,
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";

interface ModalProps {
    handleCloseModal: any;
    heading: string;
}

const SuccessModal: React.FC<ModalProps> = ({ handleCloseModal, heading }) => {
    return (
        <div className="success-root">
            <IonText color="primary">
                <IonIcon icon={checkmarkCircleOutline} className="check-icon" onClick={handleCloseModal} />
                <h4 style={{ fontWeight: "bold" }}>{heading}</h4>
            </IonText>

            <IonButton
                onClick={handleCloseModal}
            >
                Okay
            </IonButton>
        </div>

    );
};

export default SuccessModal;
