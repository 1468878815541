import { IonButton, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonModal, IonPage, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import HomeHeader from "../../components/home-header";
import { userContext } from "../../context/user-context";
import { firestore } from "../../lib/firebase";

const AllStore = () => {
    const history = useHistory()
    const [allStore, setAllStore] = useState<any>([])
    const [showIsLoggedInModal, setShowIsLoggedInModal] = useState<boolean>(false);


    useEffect(() => {
        let isMount = true

        if (isMount) {

            const getData = async () => {
                const collectionRef = await firestore.collection('store').get();
                const data: any = collectionRef.docs.map(store => store.data())
                setAllStore(data)

            }
            getData()

        }


        return () => {
            isMount = false
        }


    }, [])


    const { currentUser } = useContext(userContext)
  useEffect(() => {

    console.log("hi")

    if(!Object.keys(currentUser).length) return




    if(currentUser.isHomeOwner) {
      
history.push('/setting/analytics')      

console.log(history)
      }
  }, [currentUser])
    return (
        <IonPage>
            <HomeHeader />

            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol sizeMd='8' offsetMd='2'>
                            <div>
                                <h1>All Store</h1>
                                {
                                    allStore.map((el: any) => <IonCol className='ion-no-padding ion-no-margin' sizeMd='6' size='12'>

                                        <IonCard
/*                                         onClick={'handleClick'}
 */                                        className='d-flex align-items-center discount-card-container'
                                        >
                                            <img
                                                src={el.imageUrl}
                                                alt=""
                                                width="48px"
                                            />
                                            <div className="discount-card">
                                                <div className="discount-card-text">
                                                    <h3 style={{ marginLeft: '13px', fontSize: '15px' }} className='ion-no-padding ion-no-margin'>
                                                        {el.name}
                                                    </h3>

                                                </div>

                                            </div>




                                            <IonButton className='btn-red-cus' onClick={() =>{ 
                                                if(!Object.keys(currentUser).length)  return    
                                                setShowIsLoggedInModal(true)


                                                
                                                history.push(`/store/mystore/add/${el.id}`)
                                                }}>
                                                Create Discount
    </IonButton>
                                        </IonCard>
                                    </IonCol>)
                                }

                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>


        </IonPage>
    );
}

export default AllStore;