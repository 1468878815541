import React, { useEffect } from 'react';
import './upload-image.tsx'

import { IonButton, IonItem, IonLabel } from '@ionic/react';
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Plugins, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;

export interface UploadImageProps {
    photo: string,
    setPhoto: any,
    label?: any,
    customCss?: any
}


/* 
Have to move the css to right file */

const UploadImage: React.FC<UploadImageProps> = ({ photo, setPhoto, label, customCss }) => {

    console.log(photo)

    useEffect(() => {
        defineCustomElements(window);
    });

    const takePicture = async () => {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
        });
        var imageUrl = image.webPath;
        setPhoto(imageUrl || "");
    }; return (
        <IonItem className={`store-image-container ${customCss} ml-n1`} lines='none'>
            {label && (
                <IonLabel className="store-image" position='stacked'>
                    Store Image
                </IonLabel>
            )}

            {
                photo ? (
                    <img
                        src={photo}
                        height="200px"
                        style={{ display: "block" }}
                        alt="" />
                ) : (
                    <div className="mx-2 px-2 my-2 py-1 rounded" style={{ border: 'dotted' }}>
                        <IonButton
                            className="learn-btn"
                            fill="outline"
                            onClick={() => takePicture()}
                        >
                            Choose file / Take Photo
          </IonButton>
                    </div>
                )
            }

        </IonItem>
    );
}

export default UploadImage;