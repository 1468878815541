import "./../home/signup-in.css";
import React, { useContext, useEffect, useState } from "react";
import { StyledFirebaseAuth } from "react-firebaseui";
import "./login.css";
import firebase, { firestore, userProfileData } from "../../lib/firebase";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import { userContext } from "./../../context/user-context";
import { useHistory } from "react-router";
import HomeHeader from "../../components/home-header";

export interface LoginProps {}

const uiConfig = {
  signInFlow: "popup",
  callbacks: {
    signInSuccessWithAuthResult: () => {
      window.location.href = "/";
      return true;
    },
  },
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
};

const Login: React.FC<LoginProps> = () => {
  const history = useHistory();
  let [loginType, setLoginType] = useState("guest");
  const [guestCredentials, setGuestCredentials] = useState<any>({
    accessCode: "",
    checkOut: "",
  });
  const [isLoginError, setIsLoginError] = useState<any>(false);
  const { currentUser, setCurrentUser, setAccessCredential } =
    useContext(userContext);
  const { accessCode, checkOut } = guestCredentials;
/*   useEffect(() => {
    const tabRef: any = document.getElementById("hide-tab-bar");
    tabRef.style.display = "none";
    return () => {
      tabRef.style.display = "display";
    };
  }, []); */

  const handleLogin = async () => {
    const currentYear = new Date().getFullYear();
    let modifiedCheckout = checkOut;
    modifiedCheckout = modifiedCheckout.replace("-", "");
    modifiedCheckout = modifiedCheckout.replace("-", "");

    const propertyRef = await firestore
      .collection(`properties`)
      .where("accessCode", "==", accessCode)
      .get();
    const data = propertyRef.docs.map((el) => el.data());
    if (!data.length) {
      return setIsLoginError(true);
    }
    const calendarRef = await firestore.doc(`calendar/${data[0].id}`).get();
    const calendarData: any = calendarRef.data();
    const calendarExist = calendarData[currentYear].filter(
      (calendar: any) =>
        calendar.endDate === checkOut.replace("-", "/").replace("-", "/")
    );
    if (calendarExist.length) {
      console.log(
        new Date(calendarExist[0].endDate).getTime(),
        new Date(checkOut.replace("-", "/").replace("-", "/")).getTime(),
        calendarExist[0].endDate
      );
    }
    window.localStorage.setItem("isGuestLoggedIn", "true");
    window.localStorage.setItem(
      "credentials",
      JSON.stringify({ accessCode, checkOut: data[0].id })
    );
    setAccessCredential({ accessCode, checkOut: data[0].id });
    return history.push("/");
  };

  return (
    <IonContent className="login">
      <HomeHeader />


      {/*
            <div className="gredient">&nbsp;</div>
            <div className="separator">&nbsp;</div>
 */}
      <main
        className="login-content"
        style={{
          position: "relative",
          background: "rgb(250, 250, 250)",
          zIndex: -1,
        }}
      >
        <div className="container-sign">
          <div className="mb-2 d-block" style={{ marginTop: "40%" }}>
            <IonButton
              className="btn-red-cus "
              onClick={() => {
                setLoginType("guest");
              }}
            >
              Guest Login
            </IonButton>{" "}
            <IonButton
              className="btn-red-cus "
              onClick={() => {
                setLoginType("store");
              }}
            >
              Admin Login/Register
            </IonButton>
          </div>
          <div>
            <div className="d-flex  flex-column align-items-stretch">
              {loginType === "guest" ? (
                <div className="signbox">
                  <h1 style={{ marginLeft: "12px" }}>Guest Login</h1>

                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonLabel className="input-label">Checkout</IonLabel>
                        <IonInput
                          name="checkOut"
                          value={checkOut}
                          className="sign-input date-input"
                          onIonChange={(e: any) => {
                            setGuestCredentials({
                              ...guestCredentials,
                              [e.target.name]: e.detail?.value!,
                            });
                          }}
                          type="date"
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonLabel className="input-label">Access Code</IonLabel>
                        <IonInput
                          className="sign-input"
                          value={accessCode}
                          name="accessCode"
                          onIonChange={(e: any) => {
                            setGuestCredentials({
                              ...guestCredentials,
                              [e.target.name]: e.detail?.value!,
                            });
                          }}
                          type="text"
                          placeholder="Input the Access code"
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  {/*                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonItem>
                          <IonLabel position="stacked">Checkout Date</IonLabel>
                          <IonInput
                            name="checkOut"
                            value={checkOut}
                            onIonChange={(e: any) => {
                              setGuestCredentials({
                                ...guestCredentials,
                                [e.target.name]: e.detail?.value!,
                              });
                            }}
                            type="date"
                          />
                        </IonItem>
                        <IonItem>
                          <IonLabel position="stacked">Access Code</IonLabel>
                          <IonInput
                            value={accessCode}
                            name="accessCode"
                            onIonChange={(e: any) => {
                              setGuestCredentials({
                                ...guestCredentials,
                                [e.target.name]: e.detail?.value!,
                              });
                            }}
                            type="text"
                            placeholder="Input the Access code"
                          />
                        </IonItem>

                        
                      </IonCol>
                    </IonRow>
                  </IonGrid> */}

                  {isLoginError && (
                    <IonItem className="login-error-text" lines="none">
                      <IonText> Somethings is incorrect</IonText>
                    </IonItem>
                  )}
                  <IonButton
                    className="btn-red-cus ml-2"
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    Login
                  </IonButton>
                </div>
              ) : (
                <StyledFirebaseAuth
                  className="signbox"
                  uiConfig={uiConfig}
                  firebaseAuth={firebase.auth()}
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </IonContent>
  );
};

export default Login;
