import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import React, { useContext, useEffect, useState } from 'react';
import './redeem.css'
import { IonButton, IonCard, IonIcon, IonModal, IonToast, } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';
import { StoreContext } from '../../context/store-context';
import firebase, { firestore, getDiscountById } from './../../lib/firebase'
import Loading from '../../components/loading';
import { userContext } from '../../context/user-context';
import HomeHeader from '../../components/home-header';
import BackHeader from '../../components/back-header';


export interface RedeemProps {

}

const Redeem: React.FC<RedeemProps> = () => {
    const [showCoupon, setShowCoupon] = useState<boolean>(false);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [showIsLoggedInModal, setShowIsLoggedInModal] = useState<boolean>(false);
    const [showNoVoucher, setShowNoVoucher] = useState<boolean>(false);
    const [redeemData, setRedeemData] = useState<any>()
    const { redeemID } = useParams<any>()
    const history = useHistory();

    const { allDiscount } = useContext(StoreContext);
    const { currentUser } = useContext(userContext);
    useEffect(() => {
        if (!allDiscount || !allDiscount.length) {
            const getData = async () => {
                setIsDataLoading(true)
                const data = await getDiscountById(redeemID);
                setRedeemData(data)
                setIsDataLoading(false)
            }
            getData()
            return

        }


        const data = allDiscount.filter((el: any) => el.id === redeemID);
        setRedeemData(data[0])

    }, [redeemID])

  useEffect(() => {

    console.log("hi")

    if(!Object.keys(currentUser).length) return




    if(currentUser.isHomeOwner) {
      
history.push('/setting/analytics')      

console.log(history)
      }
  }, [currentUser])


    const deductVoucher = async () => {
/*         const voucherRef = firestore.doc(`discounts/${redeemID}`);
 */        const userRef = firestore.doc(`users/${currentUser.id}`);
        const discountCollection = firestore.collection(`usedDiscounts`);
        const usedDiscountRef = discountCollection.doc();
 /*        await voucherRef.update({
            voucherAmount: redeemData.voucherAmount - 1
        })
 */
        await userRef.update({
            estimatedSaving: parseInt(currentUser.estimatedSaving) + parseInt(redeemData.amountDiscount),
            usedDiscounts: firebase.firestore.FieldValue.arrayUnion({ id: redeemID, randomId: new Date().getMilliseconds() * Math.random() + 4 })
        })

        await usedDiscountRef.set({
            ...redeemData,
            usedBy: currentUser.id,
            createdAt: new Date()

        })

        history.push('/')

    }


    useEffect(() => {

        // posiblty constraints is if currentuser get updated will it call once again

        if (!Object.keys(currentUser).length) return


        const docRef = firestore.doc(`discounts/${redeemID}`)

        const getData = async () => {
            const promise = await docRef.get();
            const data: any = promise.data()
            const freshViewData = data.viewedBy
            let isFound;
            data.viewedBy.forEach((view: any, idx: number) => {
                if (view.id === currentUser.id) {
                    freshViewData[idx].count = view.count + 1
                    isFound = true
                    docRef.update({
                        viewedBy: freshViewData
                    })
                }
            })

            if (!isFound) {
                docRef.update({
                    viewedBy: firebase.firestore.FieldValue.arrayUnion({ id: currentUser.id, count: 1 })
                })
            }
        }

        getData()


    }, [currentUser])
    return (
        <div>
            {
                !isDataLoading ? (
                    <>

                        <BackHeader name={redeemData?.storeName} />

            <div className="redeem-content">
                            <img src={redeemData?.imageUrl} alt="" style={{ imageRendering: 'pixelated' }} width='600px' height='400px' />
                <div className="redeem-text">
                                <h1>{redeemData?.discountTitle}</h1>

                                <span>{redeemData?.websiteLink}</span>
                                <span className='font-weight-bold'> Estimated Savings: <span style={{ color: 'green' }}> ${redeemData?.amountDiscount}</span></span>
                                <span> {redeemData?.voucherAmount} uses left for this deal.</span>
                                <span style={{ color: 'red' }}>**Cashier must see you swipe.**</span>
                    {
                        !showCoupon ? (

                                        <div className={`swipable-button ${redeemData?.voucherAmount === 0 && 'swipable-button-deactive'}`} onClick={() => {

                                            if(!Object.keys(currentUser).length) return setShowIsLoggedInModal(true)

                                            if (redeemData?.voucherAmount === 0) {
                                                setShowNoVoucher(true)
                                                return;
                                            }
                                            setShowCoupon(true)
                                        }}  >
                                <div style={{ position: "absolute", }}>
                                                {redeemData?.voucherAmount === 0 ? 'No voucher Left' : 'Unlock'}
                            </div>

                            </div>
                        ) : (
                            <IonCard class='card-discount-swipe'>
                                <span className='redeem-text' style={{ fontSize: "20px" }}>10% Off in Entire Purchase</span>
                                <CountdownCircleTimer
                                    isPlaying
                                    duration={10}
                                                    onComplete={() => {
                                                        deductVoucher()
                                                    }}
                                    //@ts-ignore
                                                    colors={[["#FF2C02"]]}

                                >
                                    {({ remainingTime }) => remainingTime}
                                </CountdownCircleTimer>
                            </IonCard>

                        )
                    }

                </div>
            </div>
                    </>
                ) : <Loading />
            }
            <IonToast
                isOpen={showNoVoucher}
                onDidDismiss={() => setShowNoVoucher(false)}
                message="All Voucher have been used"
                duration={1000}
            />


            <IonModal isOpen={showIsLoggedInModal} onDidDismiss={() => setShowIsLoggedInModal(false)}>
            <div className='logged-in-modal'>
                <h3>Your are not Logged In</h3>

                <IonButton onClick={() => {
                    setShowIsLoggedInModal(false)
                }}>Cancel</IonButton>
                <IonButton onClick={() => {
                    history.push('/login')
                }}>Login</IonButton>
            </div>

            </IonModal>


        </div>
    );
}

export default Redeem;
