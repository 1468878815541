import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
} from "@ionic/react";
import { auth } from "./../../lib/firebase";
import { useState } from "react";
import "./signup-in.css";
export interface SignUpSignInProps {}

const SignUpSignIn: React.FC<SignUpSignInProps> = () => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [isUserExist, setIsUserExist] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("signup");
  const [userCredentials, setUserCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [loginCredentials, setLoginCredentials] = useState({
    loginEmail: "",
    logininPassword: "",
  });

  const { firstName, lastName, email, password } = userCredentials;
  const { loginEmail, logininPassword } = loginCredentials;

  const handleChange = (e: any, handle: any) => {
    const { name, value } = e.target;

    handle({
      ...userCredentials,
      [name]: value,
    });
  };

  const handleLogin = (e: any) => {
    const { name, value } = e.target;

    setLoginCredentials({
      ...loginCredentials,
      [name]: value,
    });
  };
  return (
    <div className="container-sign">
      <div style={{ marginTop: "20%" }}>
        <div className="d-flex align-items-center flex-column">
          <h3 className="signup-heading">Sign Up</h3>
          <span className="signup-subheading">
            Already have an account?
            <span
              onClick={() => setMode(mode === "signup" ? "signin" : "signup")}
              style={{ color: "#EC2614", fontWeight: "bold" }}
            >
              {mode === "signup" ? "sign in" : "sign up"}
            </span>
          </span>
        </div>
      </div>

      {mode === "signup" ? (
        <div>
          {isUserExist && <h1 className="user-exist">User Already Exist</h1>}
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel className="input-label">First Name</IonLabel>
                <IonInput
                  onIonChange={(e) => {
                    handleChange(e, setUserCredentials);
                  }}
                  name="firstName"
                  value={firstName}
                  className="sign-input"
                ></IonInput>
              </IonCol>
              <IonCol>
                <IonLabel className="input-label">Last Name</IonLabel>
                <IonInput
                  onIonChange={(e) => {
                    handleChange(e, setUserCredentials);
                  }}
                  className="sign-input"
                  type="text"
                  name="lastName"
                  value={lastName}
                ></IonInput>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel className="input-label">Email</IonLabel>
                <IonInput
                  onIonChange={(e) => {
                    handleChange(e, setUserCredentials);
                  }}
                  className="sign-input"
                  type="email"
                  name="email"
                  value={email}
                ></IonInput>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel className="input-label">Password</IonLabel>
                <IonInput
                  onIonChange={(e) => {
                    handleChange(e, setUserCredentials);
                  }}
                  type="password"
                  className="sign-input"
                  name="password"
                  value={password}
                ></IonInput>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            className="btn-sign"
            onClick={async () => {
              try {
                await auth.createUserWithEmailAndPassword(email, password);
                setMode("signin");
              } catch (err) {
                setIsUserExist(true);
              }
            }}
          >
            Sign Up
          </IonButton>
        </div>
      ) : (
        <div>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel className="input-label">Email</IonLabel>
                <IonInput
                  onIonChange={handleLogin}
                  name="loginEmail"
                  value={loginEmail}
                  type="email"
                  className="sign-input"
                ></IonInput>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel className="input-label">Password</IonLabel>
                <IonInput
                  onIonChange={handleLogin}
                  name="logininPassword"
                  value={logininPassword}
                  type="password"
                  className="sign-input"
                ></IonInput>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            className="btn-sign"
            onClick={async () => {
              setShowLoading(true);

              await auth.signInWithEmailAndPassword(
                loginEmail,
                logininPassword
              );
              setShowLoading(false);
              window.location.reload();
            }}
          >
            Sign In
          </IonButton>
        </div>
      )}

      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
    </div>
  );
};

export default SignUpSignIn;
