import { auth, firestore } from "./firebase"

const user = auth.currentUser
export const deleteStore = async (storeID, currentUser) => {
    console.log(storeID)
    return
    const docRef = firestore.doc(`store/${storeID}`);
    if(user.uid === currentUser.id && currentUser.storeOwner) {
        await docRef.delete()
    }



}