import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonText } from "@ionic/react";
import axios from "axios";
import { addOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import BackHeader from "../../components/back-header";
import HomeHeader from "../../components/home-header";
import { userContext } from "../../context/user-context";
import { firestore } from "../../lib/firebase";

export interface AnalyticsProps {

}

const Analytics: React.FC<AnalyticsProps> = () => {
    const [userName, setUserName] = useState<any>('');
    const [currentProperty, setCurrentProperty] = useState<any>({});
    const [showPropertyModal, setShowPropertyModal] = useState<boolean>(false);
    const [properties, setProperties] = useState([]);
    const [currentCalendarURL, setCurrentCalendarURL] = useState('');
    const { currentUser } = useContext(userContext);

    function saveProperty(property: any) {
        if (!property.id) {
            property.id = firestore.collection('properties').doc().id;
            property.createdBy = currentUser.id;
            property.access = [currentUser.id];
        }
        firestore.doc('properties/' + property.id).set(property);
        axios.get('https://us-central1-syfmc-d3022.cloudfunctions.net/updateAllCalendars').then(function (response) {
            // handle success
            console.log(response);
        }).catch((e) => {
            console.log(e);
        });
    }

    function closeModal() {
        setCurrentProperty({});
        setShowPropertyModal(false);
        setCurrentCalendarURL('');
    }
    useEffect(() => {
        setUserName(currentUser.displayName);
        firestore.collection('properties').where('createdBy', '==', currentUser.id || '').onSnapshot((data) => {
            let newProperties: any = [];
            data.docs.forEach((doc) => {
                newProperties.push(doc.data());
            });
            setProperties(newProperties);
        });
    }, [currentUser]);


    return (
        <div>
            <BackHeader />
            <IonGrid>
                <IonRow>
                    <IonCol size='10' offset='1'>
                        <IonList>
                            <IonItem>
                                <h2>Properties</h2>
                            </IonItem>
                            {properties.map((property: any) => {
                                return <IonItem>
                                    <IonLabel><b>Title: </b>{property.title}</IonLabel>
                                    <IonLabel><b>Login / Discount Access Code</b>: {property.accessCode}</IonLabel>
                                    <IonButton onClick={() => { setCurrentProperty(property); setShowPropertyModal(true); }}>Edit</IonButton>
                                </IonItem>
                            })}

                            {!properties.length && <IonText className='ml-3 my-3'> No Properties Added Yet</IonText>}

                            <IonButton className='update-btn btn-red-cus' onClick={() => setShowPropertyModal(true)}>
                                <IonIcon icon={addOutline} /> <span className="ml-1 " style={{ color: 'white' }}>Add Property</span>
                            </IonButton>
                        </IonList>
                    </IonCol>
                </IonRow>
            </IonGrid>



            <IonModal isOpen={showPropertyModal} cssClass='my-custom-class' onDidDismiss={() => { setShowPropertyModal(false) }}>
                <IonList>
                    <IonItem>
                        <h2>Property Information</h2>
                    </IonItem>
                    <IonItem>
                        <IonInput value={currentProperty?.title || ''} placeholder="Property Title" onIonChange={e => setCurrentProperty({
                            ...currentProperty,
                            title: e.detail.value! || ''
                        })}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput value={currentProperty?.accessCode || ''} placeholder="Property Access Code" onIonChange={e =>
                            setCurrentProperty({
                                ...currentProperty,
                                accessCode: e.detail.value!
                            })
                        }></IonInput>
                    </IonItem>
                    <IonItem>
                        <p className="my-0">Calendar URLs (required with access code for guest login)</p>
                    </IonItem>
                    {currentProperty?.calendars?.length ? (
                        currentProperty.calendars.map((cal: any) => {
                            return <IonItem>
                                <p className="my-0">{cal.url}</p>
                                <IonButton className='btn-red-cus' onClick={() => {
                                    setCurrentProperty({
                                        ...currentProperty,
                                        calendars: currentProperty.calendars.filter((calendar: any) => calendar.url !== cal.url)
                                    });
                                }}>Delete</IonButton>
                            </IonItem>
                        })
                    ) : (
                        <IonItem>
                            <p className="my-0">No Calendars Added</p>
                        </IonItem>
                    )}
                    <IonItem>
                        <IonLabel position="stacked">New Calendar Link</IonLabel>
                        <IonInput value={currentCalendarURL} placeholder="Enter a Property Calendar URL" onIonChange={e => setCurrentCalendarURL(e.detail.value!)}></IonInput>

                    </IonItem>
                    <IonButton className='btn-red-cus' onClick={() => {
                        if (!(currentProperty?.calendars || []).toString().includes(currentCalendarURL)) {
                            setCurrentProperty({
                                ...currentProperty,
                                calendars: currentProperty?.calendars ? [...currentProperty.calendars, { url: currentCalendarURL, platform: '' }] : [{ url: currentCalendarURL, platform: '' }]
                            });
                        }
                        setCurrentCalendarURL('');
                    }}>Add Calendar URL</IonButton>
                </IonList>

                <div>
                    <IonButton className='btn-red-cus' onClick={() => setShowPropertyModal(false)}>Cancel</IonButton>
                    <IonButton className='btn-red-cus' onClick={() => { saveProperty(currentProperty); closeModal(); }}>Save</IonButton>
                </div>
            </IonModal>
        </div>
    );
}

export default Analytics;
