import './activity-card.css'
import { IonCol } from "@ionic/react";


export interface ActivityCardProps {
    data?: any

}

const ActivityCard: React.FC<ActivityCardProps> = ({ data }) => {

    const { discountTitle, imageUrl, storeName, amountDiscount, createdAt } = data
    return (
        <IonCol size='4'>
            <div className='activity-card text-center'>
                <img
                    width='80px'
                    src={imageUrl}
                />
                <h5>{storeName}</h5>
                <h5>{discountTitle}</h5>
                <span className='font-weight-bold'>used @ {new Date(createdAt.seconds).toDateString()}</span>
                <span className='d-block' style={{ color: '#8898aa' }}>
                    Estimated Savings:
                    <span style={{ color: '#2dce89', fontWeight: "bold" }}>${amountDiscount}</span>
                </span>
            </div>
        </IonCol>
    );
}

export default ActivityCard;