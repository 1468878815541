import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'


const firebaseConfig = {
    apiKey: "AIzaSyBE0ffpZy9BzKe4acX-97W9W7eQTH-0TTE",
    authDomain: "syfmc-d3022.firebaseapp.com",
    projectId: "syfmc-d3022",
    storageBucket: "syfmc-d3022.appspot.com",
    messagingSenderId: "1070277732215",
    appId: "1:1070277732215:web:32d35894f80789decc2372"
};
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}


export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage()


export const userProfileData = async (uid) => {
    console.log(uid)
    const userDocument = await firestore.doc(`users/${uid}`);

    const snapShot = await userDocument.get();
    console.log(snapShot.exists)
    if (snapShot.exists) {
        return snapShot.data()
    } else {
        return false;
    }
}


export const createUserToDB = async (id, additionalData, isTemporary = false) => {
    id = id.replace('/', '')
    id = id.replace('/', '')

    const userCredential = {
        email: firebase.auth().currentUser ? firebase.auth().currentUser.email : '',
        displayName: firebase.auth().currentUser ? firebase.auth().currentUser.displayName : '',
    }
    const userDocument = firestore.doc(`users/${id}`);

    await userDocument.set({
        ...userCredential,
        storeOwner: false,
        estimatedSaving: 0,
        ...additionalData,
        id,
        isTemporaryAccount: isTemporary,
        usedDiscounts: []

    });

    if (firebase.auth().currentUser) {

        await firebase.auth().currentUser.updateProfile({
            displayName: userCredential.displayName,
        })
    }


    window.location.reload()

}


export const createUserAccountType = async (id, additionalData,) => {
    const userCredential = {
        email: firebase.auth().currentUser ? firebase.auth().currentUser.email : '',
        displayName: firebase.auth().currentUser ? firebase.auth().currentUser.displayName : '',
    }
    const userDocument = firestore.doc(`users/${id}`);

    await userDocument.set({
        ...userCredential,
        storeOwner: false,
        estimatedSaving: 0,
        ...additionalData,
        id,
        isTemporaryAccount: false,
        usedDiscounts: []

    });

    if (firebase.auth().currentUser) {
        await firebase.auth().currentUser.updateProfile({
            displayName: userCredential.displayName,
        })
    }



    window.location.reload()
}



export const createStore = async (obj, photo, uid, handleLoading, handleSuccess) => {
    const collectionRef = firestore.collection('store');
    const userRef = firestore.doc(`users/${uid}`);
    const docRef = firestore.doc(`store/${uid}`);
    var xhr = new XMLHttpRequest;
    xhr.responseType = 'blob';
    const updateImageUrl = async () => {
        xhr.onload = function () {
            handleLoading(true)
            var recoveredBlob = xhr.response;
            // 'file' comes from the Blob or File API
            const storageRef = storage.ref();
            storageRef.child(`store/`)
            storageRef.child(`store/${docRef.id}.jpg`).put(recoveredBlob).then((snapshot) => {
                storageRef.child(`store/${docRef.id}.jpg`).getDownloadURL().then(async url => {
                    await docRef.set({
                        id: userRef.id,
                        ...obj,
                        imageUrl: url
                    })

                    await userRef.update({
                        storeOwner: true
                    }).then(() => {
                                            handleLoading(false);
                    handleSuccess(true)
                    })



                });
            });
        };
        xhr.open('GET', photo);
        xhr.send();
    }

    await updateImageUrl()
}


export const createDiscount = async (object, setShowLoading, setShowSuccessModal, photo, status) => {
    const collectionRef = firestore.collection('discounts');
    const docRef = collectionRef.doc();

    var xhr = new XMLHttpRequest;
    xhr.responseType = 'blob';
    const updateImageUrl = async () => {
        xhr.onload = function () {
            setShowLoading(true)
            var recoveredBlob = xhr.response;
            const storageRef = storage.ref();
            storageRef.child(`discount/`)
            storageRef.child(`discount/${docRef.id}.jpg`).put(recoveredBlob).then((snapshot) => {
                storageRef.child(`discount/${docRef.id}.jpg`).getDownloadURL().then(async url => {
                    await docRef.set({
                        id: docRef.id,
                        ...object,
                        imageUrl: url,
                        status,
                        viewedBy: []
                    }).then(() => {

                    setShowLoading(false);
                    setShowSuccessModal(true)                        
                    })


                });
            });
        };
        xhr.open('GET', photo);
        xhr.send();
    }
    await updateImageUrl()


}



export const getMyProfileData = async (userId) => {
    const docRef = await firestore.doc(`user/${userId}`).get();
    return docRef.data();

}

export const getMyStoreData = async (userId) => {
    const collectionRef = await firestore.collection(`store`).where("id", "==", userId).get();
    const data = collectionRef.docs.map(el => el.data())
    return data;

}

export const getMyStoreDiscount = async (userId) => {
    console.log(userId)
    const collectionRef = await firestore.collection(`discounts`).where("storeId", "==", userId).get();
    const data = collectionRef.docs.map(el => el.data())
    return data;

}

export const getDiscountById = async (redeemID) => {
    const docRef = await firestore.doc(`discounts/${redeemID}`).get()
    return docRef.data();
}

export const updateDiscount = async (photo, obj, setShowLoading, setShowSuccessModal) => {
    const docRef = firestore.doc(`discounts/${obj.id}`);
    if (photo === obj.imageUrl) {
        docRef.update({
            ...obj,
        })

        return;
    }

    var xhr = new XMLHttpRequest;
    xhr.responseType = 'blob';
    const updateImageUrl = async () => {
        xhr.onload = function () {
            setShowLoading(true)
            var recoveredBlob = xhr.response;
            const storageRef = storage.ref();
            storageRef.child(`discount/`)
            storageRef.child(`discount/${docRef.id}.jpg`).put(recoveredBlob).then((snapshot) => {
                storageRef.child(`discount/${docRef.id}.jpg`).getDownloadURL().then(async url => {
                    await docRef.update({
                        ...obj,
                        imageUrl: url
                    })

                    setShowLoading(false);
                    setShowSuccessModal(true)


                });
            });
        };
        xhr.open('GET', photo);
        xhr.send();
    }
    await updateImageUrl();

}


export const getActivityData = async (uid) => {
    const usedDiscountsRef = firestore.collection('usedDiscounts');
    const usedDiscount = await usedDiscountsRef.where('usedBy', "==", uid).get();
    const data = await usedDiscount.docs.map(el => el.data())
    return data
}



export const updateStatusForDiscount = async (id, status) => {
    const docRef = firestore.doc(`discounts/${id}`);
    docRef.update({
        status: status
    })
}

export const deleteDiscount = async (id) => {
    const docRef = firestore.doc(`discounts/${id}`);
    docRef.delete()
}



export const updateStoreData = async  (id,obj) => {
    const docRef =  firestore.doc(`store/${id}`)

   await docRef.update(obj)

}


export default firebase;




