import { IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
import CustomInput from '../../components/custom-input';
import HomeHeader from '../../components/home-header';
import SuccessModal from '../../components/success-modal';
import UploadImage from '../../components/upload-image';
import { StoreContext } from '../../context/store-context';
import { userContext } from '../../context/user-context';
import { createDiscount, getDiscountById, getMyStoreData, updateDiscount } from '../../lib/firebase';
import './create-discount.css'
export interface CreateDiscountProps {

}




const CreateDiscount: React.FC<CreateDiscountProps> = () => {
    const history = useHistory()
    const [photo, setPhoto] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [activeInput, setActive] = useState('')
    const [storeData, setStoreData] = useState<any>({
        storeName: '',
        discountTitle: '',
        amountDiscount: '',
        selectedCatagory: 'freebies',
        websiteLink: '',
        voucherAmount: ''
    })
    const { currentUser } = useContext(userContext)
    const { storeDiscount, storeData: storeDetails, setStoreData: setStore } = useContext(StoreContext)


    const { actionType, discountID } = useParams<any>()



    useEffect(() => {
        if (actionType === 'add' && discountID) {
            setStoreData({
                storeName: '',
                discountTitle: '',
                amountDiscount: '',
                selectedCatagory: 'freebies',
                websiteLink: '',
                voucherAmount: ''
            })
            return
        }
        let isMount = true


        if (actionType === 'edit' && isMount) {

            if (!storeDiscount.length) {
                // If the data is not ther, So fetch it
                const getData = async () => {
                    const data: any = await getDiscountById(discountID)
                    setPhoto(data.imageUrl)

                    setStoreData(data)
                }
                getData()
            } else {
                //If the data is already there 
                const data = storeDiscount.filter((el: any) => el.id === discountID)[0];
                if (data) {
                    setStoreData(data)
                    setPhoto(data.imageUrl)
                    return
                }
            }
        }
        return () => {
            isMount = false
        }

    }, [actionType, discountID])

    

    useEffect(() => {
        if (actionType === 'add' && discountID) {
            console.log(actionType, discountID)
            setStoreData({
                storeName: '',
                discountTitle: '',
                amountDiscount: '',
                selectedCatagory: 'freebies',
                websiteLink: '',
                voucherAmount: ''
            })
            return
        }
        if (Object.keys(currentUser).length === 0 || actionType === 'edit') return


        if (Object.keys(storeDetails).length !== 0) {
            console.log('hi')
            setStoreData({
                ...storeData,
                storeName: storeDetails.name,
                websiteLink: storeDetails.websiteLink

            })
        } else {
            const getData = async () => {
                const storeData = await getMyStoreData(currentUser.id);
                setStore({ ...storeData[0] })

            }

            getData()
        }




    }, [storeDetails, currentUser])



    const { storeName, discountTitle, amountDiscount, selectedCatagory, websiteLink, voucherAmount } = storeData;
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        console.log(name, value)
        setStoreData({ ...storeData, [name]: value })
    }
    const handleClose = () => {
        setShowSuccessModal(false);
        history.push('/')
    }

  useEffect(() => {

    if(!Object.keys(currentUser).length) return




if(currentUser.isHomeOwner) {
      
    history.push('/setting/analytics')      
}
  }, [currentUser])





    return (
        <IonPage>
            <HomeHeader />
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size='6' offset='3'>
                            <IonText> {actionType === 'edit' ? 'Edit The Discount' : 'Create Discount For your Store'}  </IonText>


                            <IonList>
                                <div style={{ marginLeft: '-15px' }}>
                                    <CustomInput
                                    label="Store Name"
                                        name='storeName'
                                        value={storeName}
                                        handleChange={handleChange}
                                        handleActive={(active: any) => setActive(active)}
                                        active={activeInput}
                                        placeholder='Please Input your store name'

                                    />
                                    <CustomInput
                                         label="Discount Title"
                                        name='discountTitle'
                                        value={discountTitle}
                                        handleChange={handleChange}
                                        handleActive={(active: any) => setActive(active)}
                                        active={activeInput}
                                        placeholder='Please Input your discount Title'

                                    />
                                    <CustomInput
                                         label="Estimated Saving"

                                        name='amountDiscount'
                                        value={amountDiscount}
                                        handleChange={handleChange}
                                        handleActive={(active: any) => setActive(active)}
                                        active={activeInput}
                                        placeholder='Please Input Estimated Saving ($)'
                                        type='number'

                                    />
                                    <CustomInput
                                         label="Coupn Amount"
                                        name='voucherAmount'
                                        value={voucherAmount}
                                        handleChange={handleChange}
                                        handleActive={(active: any) => setActive(active)}
                                        active={activeInput}
                                        placeholder='Please input how many coupons'
                                        type='number'

                                    />
                                    <CustomInput
                                         label="Website Link"
                                        name='websiteLink'
                                        value={websiteLink}
                                        handleChange={handleChange}
                                        handleActive={(active: any) => setActive(active)}
                                        active={activeInput}
                                        placeholder='Please Input your website link'
                                        type='text'

                                    />
                                    <h6 className="ion-no-padding" style={{marginLeft: "15px"}}> Catagory</h6>
                                    <IonItem lines='none' className='select-catagory'>
                                        <IonSelect multiple value={selectedCatagory} onIonChange={(e) => setStoreData({ ...storeData, selectedCatagory: e.detail.value })} placeholder="Select Catagory">
                                            <IonSelectOption value="freebies">Freebies</IonSelectOption>
                                            <IonSelectOption value="treatsDrinks">Treats and Drinks</IonSelectOption>
                                            <IonSelectOption value="burgerSandwich">Burgers & sandwiches</IonSelectOption>
                                            <IonSelectOption value="pizza">Pizza</IonSelectOption>
                                            <IonSelectOption value="resturants">Resturants</IonSelectOption>
                                            <IonSelectOption value="entertainment">Entertainment</IonSelectOption>
                                            <IonSelectOption value="retailAuto">Retail, Auto, & More</IonSelectOption>
                                            <IonSelectOption value="healthBeauty">Health & Beauty</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>

                                    {<UploadImage
                                        photo={photo}
                                        setPhoto={(photo: any) => setPhoto(photo)}
                                        customCss="ml-n2 my-2"

                                    />}


                                </div>



                                {
                                    actionType === 'edit' ? (
                                        <IonButton className='btn-red-cus' onClick={() => {
                                            updateDiscount(photo, storeData, setShowLoading, setShowSuccessModal)
                                        }}>Update</IonButton>
                                    ) : (
                                            <IonButton className='btn-red-cus' onClick={() => {

                                                const id = discountID ? discountID : currentUser.id
                  
                              createDiscount({
                                        ...storeData,
                                        adminVoucherAmount: voucherAmount,
                                        storeImageLink: photo,
                                        storeId: id
                                    }, setShowLoading, setShowSuccessModal, photo, actionType === 'add' && discountID ? 'pending' : 'active')
                                }}>
                                    Create Discount
                                </IonButton>
                                    )
                                }


                            </IonList>
                        </IonCol>

                        <IonLoading
                            cssClass='my-custom-class'
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Please wait...'}
                            duration={5000}
                        />


                        <IonModal isOpen={showSuccessModal}>
                            <SuccessModal
                                heading='Discount Has been successfully Created'
                                handleCloseModal={handleClose} />
                        </IonModal>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default CreateDiscount;
