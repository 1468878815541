import { IonIcon } from '@ionic/react';
import './typeCard.css'
export interface TypeCardProps {
    label: any,
    name: string,
    imageUrl: string,
    active: boolean,
    handleActive: any,
    id: any,
    handleSelect: any,

}

const TypeCard: React.FC<TypeCardProps> = ({ label, imageUrl, active, handleActive, id, handleSelect, name }) => {
    return (
        <div onClick={() => {
            if (active) {
                handleActive('')
                handleSelect('')
            } else {
                handleActive(id)
                handleSelect(name)
            }

        }} className={`typeCard ml-1 ${active && 'typeCard-active'}`}>
            <img src={imageUrl} alt="" width='35px' />
            <span className='typeText'>{label}</span>
        </div>
    );
}

export default TypeCard;