import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import { createOutline, eyeOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import DiscountCard from "../../components/discount-card";
import HomeHeader from "../../components/home-header";
import Loading from "../../components/loading";
import { StoreContext } from "../../context/store-context";
import { userContext } from "../../context/user-context";
import {
  getMyStoreDiscount,
  getMyStoreData,
  updateStatusForDiscount,
  deleteDiscount,
  firestore,
} from "../../lib/firebase";
import StoreProfileHeader from "./store-profile-header";
import "./store-profile.css";
export interface StoreProfileProps {
  match: any;
}

const StoreProfile: React.FC<StoreProfileProps> = ({ match }) => {
  const [toastDetails, setToastDetails] = useState({
    isToastShow: false,
    message: "",
  });
  const [status, setStatus] = useState<string>("active");
  const history = useHistory();
  const { currentUser } = useContext(userContext);
  const {
    storeData,
    storeDiscount,
    setStoreData,
    setStoreDiscount,
    storePageLoading,
    setStorePageLoading,
    filteredDiscounts,
    setFilteredDiscounts,
  } = useContext(StoreContext);

  useEffect(() => {
    if (Object.keys(currentUser).length === 0) return;

    const getData = async () => {
      firestore.collection("discounts").onSnapshot(async (snap) => {
        const data = snap.docs.map((el) => el.data());
        let filteredData = data.filter(
          (data) => data.storeId === currentUser.id
        );
        filteredData = filteredData.filter(
          (data: any) => data.status === status
        );
        setStoreDiscount(filteredData);
        setFilteredDiscounts(filteredData);
      });


      const storeDataDB = await (await firestore.doc(`store/${currentUser.id}`).get()).data();

      setStoreData({ ...storeData[0],storeData: storeDataDB });
      setStorePageLoading(false);
    };

    getData();
  }, [currentUser]);

  useEffect(() => {
    if (Object.keys(currentUser).length === 0) return;
    let filteredData = storeDiscount.filter(
      (data: any) => data.storeId === currentUser.id
    );
    filteredData = filteredData.filter((data: any) => data.status === status);
    setFilteredDiscounts(filteredData);
  }, [status]);

  useEffect(() => {
    if (!Object.keys(currentUser).length) return;

    if (currentUser.isHomeOwner) {
      history.push("/setting/analytics");
    }
  }, [currentUser]);
  return (
    <IonPage>
      <HomeHeader />
      <IonContent>
        <IonGrid className="h-100 ion-no-padding">
          <IonRow className="h-100 ">
            <IonCol className="h-100" size="6" offset="3">
              <div className="store-profile h-100">
                {!storePageLoading ? (
                  <>
                    <StoreProfileHeader
                      name={storeData.name}
                      imageUrl={storeData.imageUrl}
                      discountTotal={storeDiscount.length}
                      handleClick={() => history.push(`${match.url}/add`)}
                      status={status}
                      handleStatus={(s: string) => setStatus(s)}
                    />

                    <IonGrid>
                      {filteredDiscounts.length > 0 ? (
                        filteredDiscounts.map((el: any, idx: number) => (
                          <IonRow>
                            <IonCol>
                              <IonCard
                                /*             onClick={handleClick}
                                 */ className="d-flex  discount-card-container"
                              >
                                <img
                                  style={{
                                    imageRendering: "pixelated",
                                    alignSelf: "center",
                                  }}
                                  src={el.imageUrl}
                                  alt=""
                                  width="50px"
                                  height="50px"
                                />

                                <div className="store-profile-card">
                                  <div className="discount-card">
                                    <div className="discount-card-text">
                                      <h3
                                        style={{
                                          marginLeft: "13px",
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                          marginTop: "10px",
                                          color: "black",
                                        }}
                                        className="ion-no-padding ion-no-margin"
                                      >
                                        {el.discountTitle}
                                      </h3>
                                      <div className="discount-card__brand d-flex justify-content-between custom-pad">
                                        {el.storeName}
                                        {/*                         <div className='discount-card__brand d-flex justify-content-between align-items-center'>
                            <IonIcon icon={location} />
                    5.09 Mi
                </div> */}
                                      </div>
                                    </div>
                                  </div>

                                  <span className="discount-use font-weight-bold">
                                    {el.adminVoucherAmount}
                                    <span
                                      className="d-block"
                                      style={{ marginLeft: "-5px" }}
                                    >
                                      use
                                    </span>
                                  </span>

                                  {el.status === "pending" ? (
                                    <IonRow className="d-flex flex-column">
                                      <IonCol size="12">
                                        <IonButton
                                          className="btn-approved"
                                          onClick={async () => {
                                            await updateStatusForDiscount(
                                              el.id,
                                              "active"
                                            );
                                            setToastDetails({
                                              isToastShow: true,
                                              message: "Discount Approved",
                                            });
                                            setStatus("pending");
                                          }}
                                        >
                                          Approved
                                        </IonButton>
                                      </IonCol>
                                      <IonCol size="12">
                                        <IonButton
                                          className="w-100 btn-decline"
                                          onClick={async () => {
                                            await deleteDiscount(el.id);
                                            setToastDetails({
                                              isToastShow: true,
                                              message:
                                                "Discount Have been deleted",
                                            });
                                          }}
                                        >
                                          Decline
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  ) : (
                                    <>
                                      <span className="discount-use">
                                        {el.viewedBy.reduce(
                                          (acc: any, cur: any) => {
                                            return acc + cur.count;
                                          },
                                          0
                                        )}
                                        <IonIcon
                                          className="icon-eye"
                                          icon={eyeOutline}
                                        />
                                      </span>
                                      <IonButton
                                        onClick={() =>
                                          history.push(
                                            `${match.url}/edit/${el.id}`
                                          )
                                        }
                                      >
                                        <IonIcon icon={createOutline} />
                                      </IonButton>
                                    </>
                                  )}
                                </div>
                              </IonCard>
                            </IonCol>
                          </IonRow>
                        ))
                      ) : (
                        <IonText className="d-flex justify-content-center mt-3">
                          No discount Have been Created
                        </IonText>
                      )}
                    </IonGrid>
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonToast
          isOpen={toastDetails.isToastShow}
          onDidDismiss={() =>
            setToastDetails({ ...toastDetails, isToastShow: false })
          }
          message={toastDetails.message}
          duration={1000}
        />
      </IonContent>
    </IonPage>
  );
};

export default StoreProfile;
