import { createContext, useState } from "react";


export const StoreContext: any = createContext({
    storeData: {},
    storeDiscount: [],
    filteredDiscounts: [],
    setFilteredDiscounts: {},
    allDiscount: [],
    storePageLoading: true,
    homePageLoading: true,
    setStoreData: () => { },
    setStoreDiscount: () => { },
    setAllDiscount: () => { },
    setStorePageLoading: () => { },
    setHomePageLoading: () => { },


});

export interface StoreProviderProps {
    children: any
}


export const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
    const [storeData, setStoreData] = useState<any>({});
    const [storeDiscount, setStoreDiscount] = useState<any>([]);
    const [filteredDiscounts, setFilteredDiscounts] = useState<any>([]);
    const [allDiscount, setAllDiscount] = useState<any>([]);
    const [storePageLoading, setStorePageLoading] = useState<boolean>(true);
    const [homePageLoading, setHomePageLoading] = useState<boolean>(true);



    return (
        <StoreContext.Provider value={{
            storeData,
            storeDiscount,
            allDiscount,
            storePageLoading,
            homePageLoading,
            filteredDiscounts,
            setFilteredDiscounts: (dis: any) => setFilteredDiscounts(dis),
            setStoreData: (data: any) => setStoreData(data),
            setStoreDiscount: (discount: any) => setStoreDiscount(discount),
            setAllDiscount: (allDiscount: any) => setAllDiscount(allDiscount),
            setStorePageLoading: (val: boolean) => setStorePageLoading(val),
            setHomePageLoading: (val: boolean) => setHomePageLoading(val),

        }}>
            {children}
        </StoreContext.Provider>
    )
}
