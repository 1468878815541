import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { cashOutline, ellipse, giftOutline, personOutline, settingsOutline, shareOutline, square, storefrontOutline, ticketOutline, triangle } from "ionicons/icons";
import { Redirect, Route, Switch, useHistory } from "react-router";
import './mainTab.css'

import Homepage from "../pages/homepage/homepage";
import { useContext, useEffect } from "react";
import { userContext } from "../context/user-context";

export interface MainTabProps {

}




const MainTab: React.FC<MainTabProps> = () => {
    const history = useHistory()
    const { currentUser } = useContext(userContext)

    

    return (
        <IonTabs>
        <IonRouterOutlet>
                <Switch>

                <Route path="/">
                    <Homepage />
                </Route>
                </Switch>


        </IonRouterOutlet>
            <IonTabBar slot="bottom" id='hide-tab-bar'>

                {
                    !currentUser.isHomeOwner && (
                        
                <IonTabButton tab="tab3" href="/hot-deals=1">
                    <IonIcon icon={ticketOutline} />
                    <IonLabel className='label-tab'>Hot Deals</IonLabel>
                </IonTabButton>
                    )
                }

                {
                    !currentUser.isTemporaryAccount &&  Object.keys(currentUser).length && !currentUser.isHomeOwner && (<IonTabButton tab="tab5" href='/allstore' onClick={() => history.push('/allstore')}>
                        <IonIcon icon={storefrontOutline} />
                        <IonLabel className='label-tab'> All Store</IonLabel>
                    </IonTabButton>)
                }

                {
                    !Object.keys(currentUser).length && (
                        <IonTabButton tab="tab6" href='/login' onClick={() => history.push('/login')}>
                        <IonIcon icon={personOutline} />
                        <IonLabel className='label-tab'>Login/Signup</IonLabel>
                    </IonTabButton> 
                    )
                }
                {

                    Object.keys(currentUser).length && (
                                       <IonTabButton tab="tab4" href='/setting' onClick={() => history.push('/setting')}>
                    <IonIcon icon={settingsOutline} />
                    <IonLabel className='label-tab'> {currentUser.isHomeOwner ? 'Home Owner' : 'Setting'}</IonLabel>
                </IonTabButton> 
                    )
                }




        </IonTabBar>
      </IonTabs>
    );
}

export default MainTab;
