import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import firebase, {createUserAccountType, createUserToDB, firestore, userProfileData} from './lib/firebase'

import './app.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  IonApp,

} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calendar, ellipse, informationCircle, map, personCircle, shareOutline, square, triangle } from 'ionicons/icons';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useEffect, useState } from 'react';
import MainTab from './components/mainTab';
import { userContext } from './context/user-context';
import NewUserModal from './components/newUserModal';
import { StoreProvider } from './context/store-context';
import AccountTypeModal from './components/accountTypeModal';

const App: React.FC = () => {
  const [isUserExist, setIsUserExist] = useState<any>(null);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showAccountTypeModal, setAccountTypeModal] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [accessCredential, setAccessCredential] = useState<any>({})
  const [userAuth, setUserAuth] = useState<any>({})
  const { accessCode, checkOut } = accessCredential




  useEffect(() => {
    const isGuestLoggedIn = localStorage.getItem('isGuestLoggedIn');
    const credentials = localStorage.getItem('credentials');

    if (isGuestLoggedIn === 'true' && credentials) {
      const { checkOut: c, accessCode: a } = JSON.parse(credentials)

      const id = `${c}#${a}`
      const setData = async () => {
        const userData: any = await userProfileData(id);
        firestore.doc(`users/${userData.id}`).onSnapshot((data) => {
          setCurrentUser(data.data());
        });
      }
      setData()
      return

    }
    firebase.auth().onAuthStateChanged(async (user) => {

      if (user) {
        const userData: any = await userProfileData(user.uid);

        if (userData === false) {
          setUserAuth(user)
  /*           createUserToDB(user.uid);
  */         setAccountTypeModal(true)
        } else {
          // Example of realtime data
          firestore.doc(`users/${user.uid}`).onSnapshot((data) => {
            const userData: any = data.data()
            setCurrentUser(userData || {});
            setIsUserExist(true)


          });
        }
        return setShowNewUserModal(false);
      } else {
        setIsUserExist(false)

      }

        //const tabRef: any = document.getElementById('hide-tab-bar');

/*         if (Object.keys(currentUser).length) {
          tabRef.style.display = "display";
        } else {
          tabRef.style.display = "none";

        } */

     /*  */
      // window.location.href = window.location.origin + '/login';
      /*  return setShowNewUserModal(true); */


    }, (error) => {
      window.location.reload();
    });
  }, []);

  useEffect(() => {
    if (!Object.keys(accessCredential).length) return;
    const { checkOut, accessCode } = accessCredential;
    const id = `${checkOut}#${accessCode}`
    const setData = async () => {
      const userData: any = await userProfileData(id);

      if (userData === false) {
        setShowNewUserModal(true)
      } else {
        firestore.doc(`users/${userData.id}`).onSnapshot((data) => {
          setCurrentUser(data.data() || {});
          window.localStorage.setItem('isGuestLoggedIn', 'true')

        });

      }

    }
    setData()



  }, [accessCredential])




  return (
    <StoreProvider>
      <userContext.Provider
        value={{
          currentUser, setCurrentUser: (cur: any) => setCurrentUser(cur),
          accessCredential, setAccessCredential: (val: any) => setAccessCredential(val),
          isUserExist,
          setIsUserExist: (val: boolean) => setIsUserExist(val)
        }}>


<IonApp>

          <IonReactRouter>


            < MainTab />

        </IonReactRouter>

        
        <NewUserModal
            showNewUserModal={showNewUserModal}
            handleShowNewUserModal={() => setShowNewUserModal(false)}
              id={`${checkOut}#${accessCode}`}
          />
          <AccountTypeModal 
          isOpen={showAccountTypeModal}
          handleSubmit={ async (obj: any) => {

          await  createUserAccountType(userAuth.uid,obj)
          setShowNewUserModal(false)
          
          }}

          />
      </IonApp>

    </userContext.Provider>
    </StoreProvider>

  )
};

export default App;
