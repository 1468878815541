import "./discount-card.css";
import React, { useContext } from "react";
import { IonButton, IonCard, IonCol, IonIcon } from "@ionic/react";
import { location } from "ionicons/icons";
import { useHistory } from "react-router";
import { userContext} from './../context/user-context'
export interface DiscountCardProps {
  data?: any;
  handleClick?: any;
  type?: string;
  isStoreProfile?: boolean;
  isAdmin?: boolean;
}

const DiscountCard: React.FC<DiscountCardProps> = ({
  handleClick,
  children,
  type,
  data,
  isStoreProfile,
  isAdmin,
}) => {
  const {
    discountTitle,
    storeName,
    imageUrl,
    voucherAmount,
    adminVoucherAmount,
  } = data;
  const history = useHistory();

  const { currentUser } = useContext(userContext)
  return (
    <IonCol className="ion-no-padding ion-no-margin" sizeMd="6" size="12">
      <IonCard
        onClick={() => {
          console.log(!isAdmin)
          if (!isAdmin || !Object.keys(currentUser).length) {
            handleClick();
          }
        }}
        className="d-flex flex-column  discount-card-container"
      >
        <img
          style={{ imageRendering: "pixelated" }}
          src={imageUrl}
          alt=""
          width="100%"
          height="300px"
        />

        <div>
          <div className="discount-card">
            <div className="discount-card-text">
              <h3
                style={{
                  marginLeft: "13px",
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginTop: "10px",
                  color: "black",
                }}
                className="ion-no-padding ion-no-margin"
              >
                {discountTitle}
              </h3>
              <div className="discount-card__brand d-flex justify-content-between custom-pad">
                {storeName}
                {/*                         <div className='discount-card__brand d-flex justify-content-between align-items-center'>
                            <IonIcon icon={location} />
                    5.09 Mi
                </div> */}
              </div>
            </div>
          </div>

          <span className="discount-use font-weight-bold">
            {
              Object.keys(currentUser).length &&  (
                <>
            {isStoreProfile ? adminVoucherAmount : voucherAmount}

                </>
              )
            }
            <span className="d-block">use</span>
          </span>
          {isAdmin && Object.keys(currentUser).length &&  (
            <IonButton
              className="btn-red-cus"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`store/mystore/edit/${data.id}`);
              }}
            >
              Click to edit
            </IonButton>
          )}

          {children}
        </div>
      </IonCard>
    </IonCol>
  );
};

export default DiscountCard;
