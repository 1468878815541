import React from 'react';
import './loading.css'
export interface LoadingProps {
    customCss?: any
}

const Loading: React.FC<LoadingProps> = ({ customCss }) => {
    return (
        <div className={`lds-ring ${customCss}`}><div></div><div></div><div></div><div></div></div>
    );
}

export default Loading;