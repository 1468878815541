import './activity.css'
import { IonCol, IonGrid, IonRow } from "@ionic/react"
import ActivityCard from './activity-card';

import { useContext, useEffect, useState } from 'react';
import { getActivityData } from './../../lib/firebase'
import { userContext } from '../../context/user-context';
import BackHeader from '../../components/back-header';

export interface ActivityProps {

}

const Activity: React.FC<ActivityProps> = () => {
    const [activityData, setActivityData] = useState<any>([])

    const { currentUser } = useContext(userContext)

    useEffect(() => {
        let isMount = true

        if (Object.keys(currentUser).length === 0) return

        const getData = async () => {
            const data = await getActivityData(currentUser.id);
            setActivityData(data)
        }

        if (isMount) {
            getData()

        }
        return () => {
            isMount = false
        }
    }, [currentUser])
    return (
        <div>
            <BackHeader name='Activity Page' />

            <IonGrid>
                <IonRow>
                    <IonCol size='10' offset='1'>
                        <IonRow>
                            {
                                activityData.length > 0 ? (
                                    activityData.map((el: any, idx: number) => <ActivityCard key={'activity-card' + idx} data={el} />)

                                ) : (<h4>No activity Found</h4>)
                            }


                        </IonRow>
                    </IonCol>
                </IonRow>

            </IonGrid>
        </div>
    );
}

export default Activity;