const brand = <span>Burgers &  <br /> sandwiches</span>
const retail = <span>Retail, Auto, & <br /> More</span>

export const discountType = [
    {
        label: "Freebies",
        imageUrl: "https://res.cloudinary.com/dltd4gs4a/image/upload/v1620453268/giftbox_eby9n2.png",
        id: 1,
        name: 'freebies'
    },
    {
        label: "Treats and Drinks",
        imageUrl: "https://res.cloudinary.com/dltd4gs4a/image/upload/v1620453967/ice-cream_d14dmb.png",
        id: 2,
        name: 'treatsDrinks'
    },
    {
        label: brand,
        imageUrl: "https://res.cloudinary.com/dltd4gs4a/image/upload/v1620454081/burger_yzmsgw.png",
        id: 3,
        name: 'burgerSandwich'
    },
    {
        label: "Pizza",
        imageUrl: "https://res.cloudinary.com/dltd4gs4a/image/upload/v1620452974/pizza_xr5rzz.png",
        id: 4,
        name: 'pizza'
    },
    {
        label: "Resturants",
        imageUrl: "https://res.cloudinary.com/dltd4gs4a/image/upload/v1620454186/restaurant-cutlery-symbol-of-a-cross_vrqpuv.png",
        id: 5,
        name: 'resturants'
    },
    {
        label: "Entertainment",
        imageUrl: "https://res.cloudinary.com/dltd4gs4a/image/upload/v1620454356/cinema_fqiut8.png",
        id: 6,
        name: 'entertainment'
    },
    {
        label: retail,
        imageUrl: "https://res.cloudinary.com/dltd4gs4a/image/upload/v1620454577/shop_kv1yjd.png",
        id: 7,
        name: 'retailAuto'
    },
    {
        label: "Health & Beauty",
        imageUrl: "https://res.cloudinary.com/dltd4gs4a/image/upload/v1620454675/one-man-walking_z2cdao.png",
        id: 8,
        name: 'healthBeauty'
    }
]