import './start.css'
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import HomeHeader from "../../components/home-header";
import SignUpSignIn from './signup-in';
import { useContext } from 'react';
import { userContext } from '../../context/user-context';
import Loading from '../../components/loading';
import Store from '../store/store';
import { Redirect } from 'react-router-dom'

export interface StartProps {

}

const Start: React.FC<StartProps> = () => {


    const { isUserExist,currentUser } = useContext(userContext)



    return (

        <IonPage>
            <IonContent className='background-gray'>

                <HomeHeader />

           
                {
                    isUserExist === null && (<Loading />)
                }
                {
                    isUserExist && !currentUser.storeOwner && (<Store />)
                }
                {
                    isUserExist === false && <SignUpSignIn />
                }
                     {
                    isUserExist && currentUser.storeOwner && ( <Redirect to='/store/mystore' />)
                }



            </IonContent>
        </IonPage>
    );
}

export default Start;