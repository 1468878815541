import { IonButton, IonIcon } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { useContext } from "react";
import { useHistory } from "react-router";
import { userContext } from "../context/user-context";

export interface BackHeaderProps {
    name?: string
}

const BackHeader: React.FC<BackHeaderProps> = ({ name }) => {
    const history = useHistory()
    const { currentUser  }  = useContext(userContext) 


    return (
        <div className="back">
            {
                !currentUser.isHomeOwner && (
                                <IonButton className='btn-red-cus' size='small' onClick={() => history.goBack()}>
                <IonIcon icon={chevronBack} />
                Back
            </IonButton>
                )
            }

            <span>{name}</span>
        </div>
    );
}

export default BackHeader;
