import { IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow } from '@ionic/react';
import './store.css'
import React, { useContext, useEffect, useState } from 'react';
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';



import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Plugins, CameraResultType } from "@capacitor/core";
import { createStore, getMyStoreData, updateStoreData } from '../../lib/firebase';
import CustomInput from '../../components/custom-input';
import UploadImage from '../../components/upload-image';
import { userContext } from '../../context/user-context';
import SuccessModal from '../../components/success-modal';
import { useHistory, useParams } from 'react-router';
const { Camera } = Plugins;




export interface StoreProps {

}



const Store: React.FC<StoreProps> = () => {
    const { storeId } = useParams<any>()

    const [photo, setPhoto] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [addressValue,setAddressValue] = useState<any>({
        city:'',
        country: '',
        state: '',
        street: '',
        stringFormat: ''
    })
    const history = useHistory();


    const [focus, setFocus] = useState('')
    const [storeData, setStoreData] = useState({
        name: '',
        description: '',
        websiteLink: '',
        imageUrl: '',

    })

    useEffect(() => {
        if(!storeId) return
        ( async () => {
            const data: any = await getMyStoreData(storeId);
          setPhoto(data[0].imageUrl)
            setStoreData({...data[0]})
        })()
        return () => {
            
        }
    }, [storeId])

    const { name, description, websiteLink } = storeData;

    const { currentUser } = useContext(userContext)

    useEffect(() => {
        defineCustomElements(window);
    });

    const takePicture = async () => {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
        });
        var imageUrl = image.webPath;
        setPhoto(imageUrl || "");
    };

    const handleChange = (e: any) => {
        const { value, name } = e.target;
        setStoreData({ ...storeData, [name]: value })

    }

    const handleClose = () => {
        setShowSuccessModal(false);
        history.push('/')
        window.location.reload()

    }

    function getPieceOfAddress(e:any, index: any) {
        if (!e.value.structured_formatting.secondary_text ||
            (e.value.structured_formatting.secondary_text.split(",").length - 1) < index) {
          return '';
        }
        return e.value.structured_formatting.secondary_text
            .split(",")[0]
            .trim()
            .replace(/[0-9]/g, "");
      }
      

    const updatedAddress = (e: any) => {
        geocodeByPlaceId("ChIJH_imbZDuDzkR2AjlbPGYKVE")
          .then((results) => console.log(results))
          .catch((error) => console.error(error));
        setAddressValue({
          city: getPieceOfAddress(e, 0),
          country: getPieceOfAddress(e, 2),
          state: getPieceOfAddress(e, 1),
          street: e.value.structured_formatting.main_text,
          stringFormat: e.label
        });
      };
    


    useEffect(() => {
  
      console.log("hi")
  
      if(!Object.keys(currentUser).length) return
  
  
  
  
      if(currentUser.isHomeOwner) {
        
  history.push('/setting/analytics')      
  
  console.log(history)
        }
    }, [currentUser])
    return (
        <IonPage className='store-page'>

            <IonContent>

                <IonGrid className='m-grid-input'>
                    <IonRow>
                        <IonCol>
                            <h2 className='ion-no-padding ion-no-margin ml-3'> {storeId ? `Update Your Store (${name})` : 'Create Your Store'}</h2>
                        </IonCol>
                    </IonRow>


                    <IonList>
                        <CustomInput
                            name='name'
                            value={name}
                            handleChange={handleChange}
                            handleActive={(val: any) => setFocus(val)}
                            active={focus}
                            placeholder='Enter Your Store Name'
                            label='Store Name'
                        />
                        <CustomInput
                            name='description'
                            value={description}
                            handleChange={handleChange}
                            handleActive={(val: any) => setFocus(val)}
                            active={focus}
                            placeholder='Enter Your Store Description'
                            label='Store Description'
                        />

                        <IonGrid className='mx-2 mt-2'>

                                <IonLabel>Select Your Address</IonLabel>
                        <GooglePlacesAutocomplete
                        
                              apiKey="AIzaSyBctVpXpDF7LVk2qGvUmWV3PfK2rCgFaP8"

                              selectProps={{
                                addressValue,
                                onChange: updatedAddress,
                                styles: {
                                    //@ts-ignore
                                  menu: provided => ({ ...provided, zIndex: 9999 }),
                                },
                              }}
                          />
                        </IonGrid>


                        <UploadImage
                            
                            label="Store Image"
                            photo={photo}
                            setPhoto={(photo: any) => setPhoto(photo)}
                        />
                        <CustomInput
                            name='websiteLink'
                            value={websiteLink}
                            handleChange={handleChange}
                            handleActive={(val: any) => setFocus(val)}
                            active={focus}
                            placeholder='Enter Your Website Link'
                            label='Website Link'
                        />
                    </IonList>

                    <IonRow>
                        <IonCol>
                            {
                                storeId ? (
                                    <IonButton onClick={ async () => {
                                        await updateStoreData(storeId,{...storeData, imageUrl: photo})
                                        setShowUpdateModal(true)

                                    }}>Update</IonButton>
                                ) : (
                                     <IonButton className='ml-3' onClick={() => {
                                createStore({ ...storeData, id: currentUser.id,...addressValue  }, photo, currentUser.id, setShowLoading, setShowSuccessModal)
                            }}>
                                Submit
                            </IonButton>
                                )
                            }
                           
                        </IonCol>


                        <IonLoading
                            cssClass='my-custom-class'
                            isOpen={showLoading}
                            onDidDismiss={() => setShowLoading(false)}
                            message={'Please wait...'}
                            duration={5000}
                        />


                        <IonModal isOpen={showSuccessModal}>
                            <SuccessModal
                                heading='Store Has been successfully Created'
                                handleCloseModal={handleClose} />
                        </IonModal>
                        <IonModal isOpen={showUpdateModal}>
                            <SuccessModal
                                heading='Store Has been successfully Updated'
                                handleCloseModal={handleClose} />
                        </IonModal>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage>



    );
}

export default Store;