import { IonButton  } from "@ionic/react" 
import { useContext } from 'react';
import { useHistory } from "react-router";
import { userContext } from '../context/user-context';
import './home-header.css'
export interface HomeHeaderProps {
    customCss?: string
}

const HomeHeader: React.FC<HomeHeaderProps> = ({ customCss }) => {
    const { currentUser } = useContext(userContext)
    const history = useHistory()
    return (
        <header className={`home-header ${customCss && customCss}`}>
            {/*@ts-ignore */}
            <div className='d-flex margin-res' style={{ marginLeft: '42%' }} >
                <img src="https://res.cloudinary.com/dltd4gs4a/image/upload/v1622181539/fast-food_rghuc8.png" alt="" />
                <span className='d-flex align-items-center font-weight-bold' style={{ marginTop: '10px' }}>Discounts</span>

            </div>

{/*             <div className="header-earning">
                
                                       <span className='header-earning'> Estimated: <span style={{
                    color: '#4bce4b',
                    fontWeight: 'bold',
                }}>{currentUser?.estimatedSaving}$</span> </span> 
                    

            </div> */}
        </header>
    );
}

export default HomeHeader;
