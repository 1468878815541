import './setting.css'
import {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList, IonModal,
    IonPage,
    IonRow,
    IonToast
} from "@ionic/react";
import { addOutline, analyticsOutline, logOutOutline, people, personCircleOutline, saveOutline } from "ionicons/icons";
import BackHeader from "../../components/back-header";
import CustomInput from '../../components/custom-input';
import { useContext, useEffect, useState } from 'react';
import firebase, { firestore } from './../../lib/firebase'
import { userContext } from '../../context/user-context';
import { useHistory } from 'react-router';
import axios from 'axios';

export interface SettingProps {

}

const Setting: React.FC<SettingProps> = () => {
    const history = useHistory();
    const [userName, setUserName] = useState<any>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const { currentUser } = useContext(userContext);
    const [properties, setProperties] = useState([]);
    const [currentProperty, setCurrentProperty] = useState<any>({});
    const [showPropertyModal, setShowPropertyModal] = useState<boolean>(false);
    const [currentCalendarURL, setCurrentCalendarURL] = useState('');

    useEffect(() => {
        setUserName(currentUser.displayName);
        firestore.collection('properties').where('createdBy', '==', currentUser.id || '').onSnapshot((data) => {
            let newProperties: any = [];
            data.docs.forEach((doc) => {
                newProperties.push(doc.data());
            });
            setProperties(newProperties);
        });
    }, [currentUser]);

    const updateUserName = async (name: string) => {
        const docRef = firestore.doc(`users/${currentUser.id}`);
        await docRef.update({
            displayName: name
        })

        await firebase.auth().currentUser?.updateProfile({
            displayName: name,
        })
        setShowToast(true)
    }

    function saveProperty(property: any) {
        if (!property.id) {
            property.id = firestore.collection('properties').doc().id;
            property.createdBy = currentUser.id;
            property.access = [currentUser.id];
        }
        firestore.doc('properties/' + property.id).set(property);
        axios.get('https://us-central1-syfmc-d3022.cloudfunctions.net/updateAllCalendars').then(function (response) {
            // handle success
            console.log(response);
        }).catch((e) => {
            console.log(e);
        });
    }

    function closeModal() {
        setCurrentProperty({});
        setShowPropertyModal(false);
        setCurrentCalendarURL('');
    }

    return (
        <div>
            <BackHeader name='Setting' />
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonRow>
                            <IonCol size='3'>
                                <div className='d-flex flex-column setting-card align-items-center' onClick={() => history.push('/setting/activity')}>
                                    <IonIcon className='setting-card-icon' icon={personCircleOutline} />
                                    <span className='text-center'>
                                        View My <br /> profile Activity
                                    </span>
                                </div>
                            </IonCol>
                            {
                                !currentUser.isTemporaryAccount && (<IonCol size='3'>
                                <div className='d-flex flex-column setting-card align-items-center' onClick={() => history.push('/setting/analytics')}>
                                    <IonIcon className='setting-card-icon' icon={analyticsOutline} />
                                    <span className='text-center'>
                                        View AirBnB <br /> analytics
                                    </span>
                                </div>
                                </IonCol>)
                            }

                        </IonRow>

                        <div className='setting-profile-container'>
                            <div className="setting-profile-header">
                                Profile Information
                            </div>

                            <CustomInput
                                value={userName}
                                handleChange={(e: any) => setUserName(e.detail.value)}
                            >
                                <IonIcon className='mr-2' icon={people} />
                            </CustomInput>


                            <div>
                                <IonButton className='btn-red-cus update-btn' onClick={() => updateUserName(userName)}>
                                    <IonIcon icon={saveOutline} /> <span className="ml-1 " style={{ color: 'white' }}>update</span>
                                </IonButton>
                                <IonButton className='update-btn btn-red-cus' onClick={ async () => {
                                   await   firebase.auth().signOut()
                                    localStorage.removeItem('isGuestLoggedIn');
                                    localStorage.removeItem('credentials');
                                    history.push("/login")
                                    window.location.reload()
                                }}>
                                    <IonIcon icon={logOutOutline} /> <span className="ml-1" style={{ color: 'white' }}>Logout</span>
                                </IonButton>
                            </div>
                        </div>
                    </IonCol>
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        message="Your Name have been saved."
                        duration={1000}
                    />
                </IonRow>
            </IonGrid>
        </div>
    );
}

export default Setting;
