import { IonPage } from '@ionic/react';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import HomeHeader from '../../components/home-header';
import { userContext } from '../../context/user-context';
import Home from '../home/home';
import Start from '../home/start';
import Login from '../login/login';
import Redeem from '../redeem/redeem';
import Activity from '../setting/activity';
import Analytics from '../setting/analytics';
import Setting from '../setting/setting';
import AllStore from '../store/all-store.component';
import CreateDiscount from '../store/create-discount';
import Store from '../store/store';
import StoreProfile from '../store/store-profile';

export interface HomepageProps {

}


const Homepage: React.FC<HomepageProps> = () => {

    const history = useHistory()
    const { currentUser } = useContext(userContext)
  useEffect(() => {

    console.log("hi")

    if(!Object.keys(currentUser).length) return




    if(currentUser.isHomeOwner) {
      
history.push('/setting/analytics')      

      }
  }, [currentUser])
    return (
        <div className='homepage'>

            {window.location.pathname !== '/login' && !window.location.pathname.includes('/store') && (<HomeHeader />
            )}
            <Switch>

                <Route exact path='/' component={Home} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/start' component={Start} />
                <Route exact path='/allstore' component={AllStore} />
                <Route exact path='/create-store' component={Store} />
                <Route exact path='/update-store/:storeId' component={Store} />
                <Route exact path='/setting' component={Setting} />
                <Route exact path='/setting/activity' component={Activity} />
                <Route exact path='/setting/analytics' component={Analytics} />
                <Route exact path='/:id' component={Home} />
                <Route exact path='/redeem/:redeemID' component={Redeem} />
                <Route exact path='/store/mystore' component={StoreProfile} />
                <Route exact path='/store/mystore/:actionType' component={CreateDiscount} />
                <Route exact path='/store/mystore/:actionType/:discountID' component={CreateDiscount} />
            </Switch>

        </div>            

    );
}

export default Homepage;