import "./home.css";
import SearchInput from "../../components/search-Input";
import TypeCard from "../../components/typeCard";
import { disc, gift } from "ionicons/icons";
import DiscountCard from "../../components/discount-card";
import { IonButton, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import { useHistory } from "react-router";
import { useContext, useEffect, useState } from "react";
import { userContext } from "../../context/user-context";
import { StoreContext } from "../../context/store-context";
import { firestore } from "./../../lib/firebase";
import Loading from "../../components/loading";
import { discountType } from "../../configuration/type-discount";

export interface HomeProps {}

const getSearchResult = (selectedFilter: any, data: any) => {
  if (!selectedFilter) return data;
  const { searchText, catagory } = selectedFilter;
  console.log(catagory)

  let filteredData: any[] = [];
  data.forEach((search: any) => {
    if (
      search.discountTitle.toLowerCase().includes(searchText.toLowerCase()) ||
      search.storeName.toLowerCase().includes(searchText.toLowerCase())
    ) {
      filteredData.push(search);
    }
  });
  if (catagory) {
    filteredData = filteredData.filter(
      (discount) => discount.selectedCatagory.includes(catagory) 
    );
  }

  return filteredData;
};

const Home: React.FC<HomeProps> = () => {
  const history = useHistory();
  const [selectedFilter, setSelectedFilter] = useState<any>({
    searchText: "",
    catagory: "",
  });
  const [typeActive, setTypeActive] = useState<any>(1);
  const [filteredDiscount, setFilteredDiscount] = useState<any>([]);
  const [searchFocus, setSearchFocus] = useState<any>(false);
  const { allDiscount, homePageLoading, setHomePageLoading, setAllDiscount } =
    useContext(StoreContext);

  const { searchText } = selectedFilter;


  const { currentUser } = useContext(userContext)
useEffect(() => {


  if(!Object.keys(currentUser).length) return




  if(currentUser.isHomeOwner) {
    
history.push('/setting/analytics')      

console.log(history)
    }
}, [currentUser])

  useEffect(() => {
    if (!Object.keys(currentUser).length) return;

    const getData = async () => {
      const collectionRef = firestore.collection("discounts");
      collectionRef.onSnapshot(async (el) => {
        let data: any[] = el.docs.map((el) => el.data());

        data = data.filter((d) => d.status === "active");
        data.map((discount) =>
          currentUser.usedDiscounts.map((discountId: any) => {
            const data = discount;

            if (discountId.id === discount.id) {
              data.voucherAmount = data.voucherAmount - 1;
            }
            return data;
          })
        );
        setAllDiscount(data);
        setFilteredDiscount(data);
        setHomePageLoading(false);
      });
    };
    getData();

    return () => {};
  }, [currentUser]);

  useEffect(() => {

    if(Object.keys(currentUser).length) return

    const getData = async () => {
      const collectionRef = firestore.collection("discounts");
      collectionRef.onSnapshot(async (el) => {
        let data: any[] = el.docs.map((el) => el.data());

        data = data.filter((d) => d.status === "active");
        setAllDiscount(data);
        setFilteredDiscount(data);
        setHomePageLoading(false);
      });
    };
    getData();

  }, [])

  useEffect(() => {
    const data = getSearchResult(selectedFilter, allDiscount);
    setFilteredDiscount(data);
  }, [selectedFilter]);

  const handleChange = (e: any) => {
    setSelectedFilter({ ...selectedFilter, searchText: e.detail.value });
  };

  const handleSelect = (e: string) => {
    setSelectedFilter({ ...selectedFilter, catagory: e });
  };

  return (
    <div style={{ overflowY: "auto" }}>
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol size="12" offset="0" sizeMd="10" offsetMd="1">
            <div className="home">
              <div className="home-search-bar">
                <div className="d-flex  ml-1 justify-content-between align-items-center mx-2">
                  <div className="d-flex">
                    {discountType.map(({ id, ...otherProps }) => (
                      <TypeCard
                        active={id === typeActive}
                        key={id}
                        handleSelect={handleSelect}
                        handleActive={(id: any) => setTypeActive(id)}
                        id={id}
                        {...otherProps}
                      />
                    ))}
                  </div>

                  {currentUser?.isTemporaryAccount || !Object.keys(currentUser).length ? (
                    ""
                  ) : currentUser?.storeOwner ? (
                    <IonButton
                      className="btn-red-theme"
                      onClick={() => history.push("store/mystore")}
                    >
                      Discover My Store
                    </IonButton>
                  ) : (
                    <IonButton
                      className="btn-red-theme"
                      onClick={() => history.push("create-store")}
                    >
                      Create Your Store
                    </IonButton>
                  )}
                </div>

                <SearchInput
                  value={searchText}
                  handleChange={handleChange}
                  handleClear={() =>
                    setSelectedFilter({ ...selectedFilter, searchText: "" })
                  }
                  focus={searchFocus}
                  handleFocus={() => setSearchFocus(true)}
                />
              </div>
              <div className="discount">
                <IonGrid>
                  <IonRow>
                    <IonCol
                      size="12"
                      offset="0"
                      sizeSm="10"
                      offsetSm="1"
                      sizeMd="8"
                      offsetMd="2"
                    >
                      {!homePageLoading ? (
                        <IonRow className="padding-bottom-120">
                          {filteredDiscount.length > 0 ? (
                            filteredDiscount.map((el: any) => (
                              <DiscountCard
                                data={el}
                                key={el.id}
                                handleClick={() =>
                                  history.push(`/redeem/${el.id}`)
                                }
                                isAdmin={!currentUser?.isTemporaryAccount}
                              />
                            ))
                          ) : (
                            <h6 className="mx-auto" style={{ color: "white" }}>
                              No discount available
                            </h6>
                          )}
                        </IonRow>
                      ) : (
                        <Loading customCss="home-loader" />
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Home;
